import {PluginFunction, PluginObject} from 'vue';

export interface ComponentsOptions {
    [key: string]: any;
}

const requireModule = require.context('.', true, /\.vue$/)

const camelize = (str: string) => {
    return str.replace(/\W+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

class Components implements PluginObject<ComponentsOptions> {
    install: PluginFunction<ComponentsOptions> = (Vue, options) => {
        requireModule.keys().forEach(component => {
            const path = component.replace(/(\.\/|\.vue)/g, '')
            const splitPath = path.split('/')
            const componentName = splitPath.pop()
            if (componentName) {
                Vue.component(
                    componentName,
                    requireModule(component).default
                )
            }
        })
    }
}

const components: Components = new Components();

export default components
