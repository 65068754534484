































import Vue from 'vue';
import Component from 'vue-class-component';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {Location} from "vue-router/types/router";

@Component<CoreMenu>({
  computed: {
    ...mapGetters('account', ['getRole']),
    ...mapState('account', ['role']),
    ...mapGetters('App', ['getDrawer'])
  },
  i18n: {
    messages: {
      en:{
        inicio: 'Home',
        perfil: 'Profile',
        generar: 'Generate QR',
        codigos: 'QR Code',
        registrar:'Register QR',
        listar:'My Pets',
        boton:'Exit'
      },
      es: {
        inicio: 'Inicio',
        perfil: 'Perfil',
        generar: 'Generar QR',
        codigos: 'Códigos QR',
        registrar:'Registrar QR',
        listar:'Mis Mascotas',
        boton:'Salir'
      }
    }
  },
  methods: {
    ...mapMutations('App', ['setDrawer']),
    ...mapActions('Auth', ['logout'])
  },
  watch: {
    role(val) {
      if (val.name === 'admin') {
        this.menu = this.menuAdmin
      } else if(val.name  === 'pet_owner') {
        this.menu = this.menuCuidador
      }
    }
  }
})
export default class CoreMenu extends Vue {
  menu: (Location & { title: any })[] = []
  menuAdmin: (Location & { title: any })[] = [
    {
      title: ()=>this.$t('inicio'),
      name: 'Home'
    },
    {
      title: ()=>this.$t('perfil'),
      name: 'Perfil'
    },
    {
      title: ()=>this.$t('generar'),
      name: 'QrGenerar'
    },
    {
      title: ()=>this.$t('codigos'),
      name: 'QrMain'
    },
  ]
  menuCuidador: (Location & { title: any })[] = [
    {
      title:  ()=>this.$t('inicio'),
      name: 'Home'
    },
    {
      title: ()=>this.$t('perfil'),
      name: 'Perfil'
    },
    {
      title:()=> this.$t('registrar'),
      name: 'RegistrarMascota'
    },
    {
      title: ()=> this.$t('listar'),
      name: 'ListarMascota'
    }
  ]

  getRole!: string
  getDrawer!: boolean
  setDrawer!: (val: boolean) => void
  logout!: () => Promise<boolean>

  get drawer() {
    return this.getDrawer
  }

  set drawer(val: boolean) {
    this.setDrawer(val)
  }

  signOut() {
    this.logout()
        .then(() => {
          this.drawer = false
          this.$router.replace({name: 'Login'}).finally()
        }).catch(() => null)
  }
}
